
import { defineComponent, ref, onBeforeMount, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiTaskManagement, ApiBase } from "@/core/api";
import Editor from "@tinymce/tinymce-vue";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "add-standard-script-modal",
  components: {
    editor: Editor,
  },
  emits: ["update-list"],
  setup(props, { emit }) {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const addStandardScriptModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const scrpitRef = ref<HTMLElement | null>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      system_status: 10,
      subject: "",
      type: "",
      suitable_conditions: [],
      // suitable_account: [],
      script: "",
    });
    const { t } = useI18n();

    const rules = ref({
      subject: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      suitable_conditions: [
        {
          required: true,
          // asyncValidator: (rule, value) => {
          //   return new Promise<void>((resolve, reject) => {
          //     if (formData.value.type != "1") {
          //       reject(t("common.isRequired"));
          //     } else {
          //       resolve();
          //     }
          //   });
          // },
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const subjectOptions = ref([]);
    const subjectLoading = ref<boolean>(false);
    const typeOptions = ref([
      // { label: "Account Validation", value: "1" },
      // { label: "Call-Out", value: "2" },
      // { label: "Quick Inquiry", value: "3" },
    ]);
    const typeLoading = ref<boolean>(false);
    const callOutSuitableConditionsOptions = ref([]);
    const ouickInquirySuitableConditionsOptions = ref([]);
    const suitableConditionsLoading = ref<boolean>(false);
    const suitableAccountOptions = ref([]);
    const suitableAccountLoading = ref<boolean>(false);

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "call_out_subject",
          "quick_inquiry_comment_tagging",
          "distribution_task_type",
        ],
      });
      if (data.code == 0) {
        callOutSuitableConditionsOptions.value =
          data.data.quick_inquiry_comment_tagging.items;
        ouickInquirySuitableConditionsOptions.value =
          data.data.call_out_subject.items;
        typeOptions.value = data.data.distribution_task_type.items;
      }
    };

    const typeChange = () => {
      formData.value.suitable_conditions = [];
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiTaskManagement.addStandardScript(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addStandardScriptModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(addStandardScriptModalRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onBeforeMount(() => {
      getDropdownOptions();
    });

    return {
      loading,
      formData,
      rules,
      formRef,
      addStandardScriptModalRef,
      submitButton,
      scrpitRef,
      subjectOptions,
      subjectLoading,
      typeOptions,
      typeLoading,
      callOutSuitableConditionsOptions,
      ouickInquirySuitableConditionsOptions,
      suitableConditionsLoading,
      suitableAccountOptions,
      suitableAccountLoading,
      typeChange,
      submit,
      handleDiscard,
      resetForm,
      t,
    };
  },
});
