
import { ApiBase } from "@/core/api";
import { defineComponent, onBeforeMount, ref } from "vue";
import _ from "lodash";
import { useI18n } from "vue-i18n";

interface Filter {
  system_status: string | number;
  type: string;
  suitable_conditions: Array<string>;
  suitable_account: Array<string>;
}

export default defineComponent({
  name: "standard-script-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      system_status: "",
      type: "",
      suitable_conditions: [],
      suitable_account: [],
    });
    const subjectOptions = ref([]);
    const subjectLoading = ref<boolean>(false);
    const typeOptions = ref([]);
    const typeLoading = ref<boolean>(false);
    const callOutSuitableConditionsOptions = ref([]);
    const ouickInquirySuitableConditionsOptions = ref([]);
    const suitableConditionsLoading = ref<boolean>(false);
    const suitableAccountOptions = ref([]);
    const suitableAccountLoading = ref<boolean>(false);
    const { t } = useI18n();

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "quick_inquiry_comment_tagging",
          "call_out_subject",
          "distribution_task_type",
        ],
      });
      if (data.code == 0) {
        callOutSuitableConditionsOptions.value =
          data.data.quick_inquiry_comment_tagging.items;
        ouickInquirySuitableConditionsOptions.value =
          data.data.call_out_subject.items;
        typeOptions.value = data.data.distribution_task_type.items;
      }
    };

    const typeChange = () => {
      data.value.suitable_conditions = [];
    };

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onBeforeMount(() => {
      getDropdownOptions();
    });

    return {
      data,
      formRef,
      subjectOptions,
      subjectLoading,
      typeOptions,
      typeLoading,
      callOutSuitableConditionsOptions,
      ouickInquirySuitableConditionsOptions,
      suitableConditionsLoading,
      suitableAccountOptions,
      suitableAccountLoading,
      t,
      typeChange,
      submit,
      handleReset,
    };
  },
});
